import { render, staticRenderFns } from "./vcTaxonomyForm.vue?vue&type=template&id=3833addc&scoped=true&lang=pug&"
import script from "./vcTaxonomyForm.vue?vue&type=script&lang=js&"
export * from "./vcTaxonomyForm.vue?vue&type=script&lang=js&"
import style0 from "./vcTaxonomyForm.vue?vue&type=style&index=0&id=3833addc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3833addc",
  null
  
)

export default component.exports