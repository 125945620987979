<template lang="pug">
  a-modal(
    :title='title'
    :width='width'
    :visible="show"
    :footer="footer"
    :confirmLoading="loading"
    :afterClose="afterClose"
    @ok="submit"
    @cancel="cancel")
    
    //- a-row
    //-   a-col(span="10")
    //-     //- pre(style="font-size:11px;line-height:1;") {{ modal }}
        
    //-     a-divider data
    //-     pre(style="font-size:11px;line-height:1;") {{ data }}
        
    //-     a-divider options
    //-     pre(style="font-size:11px;line-height:1;") {{ options }}

    //-   a-col(span="14")

    a-spin(:spinning="loading")
      a-form-model(ref="form" :model="data" v-bind="formLayout")
        //- pre {{ data }}
        
        a-form-model-item(label="主键ID" v-show="false")
          a-input(disabled v-model="data.id")
          
        //- a-form-model-item(label="父级:" v-if="options.hierarchy")
          //- | {{ select.data.length }}
          a-select(
            :disabled="select.disabled"
            :allowClear="true"
            option-label-prop="label")
            //- v-decorator="['parent', {} ]")
            a-icon(slot="suffixIcon" type="loading" v-if="select.loading")
            a-select-option(v-for="(item, index) in select.data" :key="index" :value="item.id" :label="item.name")
              span.m-r-5(v-if="item.level")
                template(v-for="i in item.level") &mdash;
              span {{ item.name }}

        a-form-model-item(label="名称:" :rule="[{ required: true, message: '名称必须!' }]")
          a-input(autocomplete="off" v-model="data.name" placeholder="名称")

        a-form-model-item(label="名称（英文）:")
          a-input(autocomplete="off" v-model="data.en_name" placeholder="名称（英文）")

        a-form-model-item(label="描述:" v-if="options.description")
          a-textarea(:auto-size="{ minRows: 2, maxRows: 3 }" v-model="data.description")

        a-form-model-item(label="超链接:" v-if="options.url")
          a-input(v-model="data.url")

        a-form-model-item.m-b-0(label="排序:" v-if="options.sort")
          a-input-number(:min="0" v-model="data.menu_order")
          //- v-decorator="['menu_order', { initialValue: 0 } ]")
          
        a-form-model-item(:label="options.gallery.label || `相册:`" v-if="options.gallery")
          small(slot="extra") {{ options.gallery.tip }}
          vc-uploader(:options="options.gallery" @success="onSuccess")
          //- v-decorator="['gallery', {} ]"
          //- a-input(type="hidden" v-decorator="['gallery', {} ]")
          //- pre(style="font-size:11px;line-height:1;") {{ data.gallery }}
          //- a-button(@click="() => this.$refs.vcAttachment.open({ key: 'gallery' })") 上传文件

        a-form-model-item(label="缩略图:" v-if="options.thumbnail")
          small(slot="extra") {{ options.thumbnail.tip }}
          vc-uploader(:options="options.thumbnail" @success="onSuccess")
          //- v-decorator="['thumbnail', {} ]"

          //- https://blog.csdn.net/weixin_43141746/article/details/107466905
          //- https://blog.csdn.net/zz00008888/article/details/116058466
          //- Switch
          //- https://blog.csdn.net/huyande123/article/details/105409222/
          //- antdv的Upload组件实现前端压缩图片并自定义上传功能
          //- https://blog.51cto.com/u_15098014/2613659
          //- antdv 表单动态规则校验
          //- https://juejin.cn/post/6845166890474536974
          //- 关于vue v-decorator
          //- https://www.jianshu.com/p/c24997512be7
              
    vc-attachment(
      ref="vcAttachment"
      :modal="modal_attachment"
      @ok="modal_attachment_ok")
</template>

<script>
//- import pick from 'lodash.pick'

import vcUploader from '@/components/vcUploader'
import vcAttachment from '@/components/vcAttachment'

export default {
  name: 'vcTaxonomyForm',
  components: {
    'vc-attachment': vcAttachment,
    'vc-uploader': vcUploader
  },
  props: {
    modal: {
      type: Object,
      required: true
    },
    //- rules: {
    //-   type: Object,
    //-   default: () => {
    //-     return {
    //-       name: []
    //-     }
    //-   }
    //- }
  },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      }
    }
    return {
      data: {},

      loading: false,
      show: false,
      title: '',
      width: 580,
      footer: undefined,

      select: {
        loading: false,
        disabled: false,
        data: [],
      },

      modal_attachment: {
        show: false
      }
    }
  },
  computed: {
    options() {
      return {
        hierarchy: this.modal.options.hierarchy || false,
        description: this.modal.options.description || false,
        url: this.modal.options.url || false,
        sort: this.modal.options.sort || false,
        thumbnail: this.modal.options.thumbnail || undefined,
        gallery: this.modal.options.gallery || undefined
      }
    },
    // 表单字段
    fields() {
      const fields = [ 'id', 'name' ];
      if (this.options.hierarchy) fields.push('parent');
      if (this.options.description) fields.push('description');
      if (this.options.sort) fields.push('menu_order');
      if (this.options.url) fields.push('url');
      if (this.options.thumbnail) fields.push('thumbnail');
      if (this.options.gallery) fields.push('gallery');
      if (this.options.video) fields.push('video');
      return fields;
    }
  },
  methods: {
    //- 媒体库方法
    modal_attachment_ok(items, options) {
      if (options.key) {
        //- 放到表格值对象里
        //- this.form.setFieldsValue({
        //-   [options.key]: JSON.stringify(items.map(item => {
        //-     return {
        //-       id: item.id,
        //-       name: item.name,
        //-       src: item.guid
        //-     }
        //-   }))
        //- });
        
        //- 放到默认data对象里
        this.$set(this.data, options.key, items);
      }
    },

    //- 加载父级
    async _getParent() {
      const { options } = this.modal;
      if (!options.taxonomy || !options.hierarchy) return;

      this.select.loading = true;
      this.select.disabled = true;
      try {
        this.select.data = await this.$http.get(options.taxonomy, {
          params: {
            limit: -1,
            __not_in: this.data.id
          }
        });
        this.select.disabled = false;
        this.select.loading = false;
      } catch(err) {
        this.select.loading = false;
        this.$message.error(err.message);
      }
    },

    //- 获取数据
    async _getDetail(id) {
      this.data.id = id;

      try {
        this.loading = true;
        this.footer = null;
        this.data = await this.modal.api.get(id);
        //- console.log(this.data)
        this.loading = false;
        this.footer = undefined;

      } catch(err) {
        this.loading = false;
        this.$message.error(err.message);
      }
    },

    //- 打开
    open(title) {
      this.title = title;
      this.show = true;
      this._getParent();
    },

    //- 编辑
    edit(title, id) {
      this.title = title;
      this.show = true;
      this._getDetail(id);
      this._getParent();
    },

    //- 关闭
    cancel() {
      this.loading = false;
      this.show = false;
      this.$emit('cancel');
    },

    //- 关闭后
    afterClose() {
      this.title = '';
      this.data = {};
      this.$refs['form'].resetFields();
    },

    //- 提交
    submit() {
      this.loading = true;
      this.$refs['form'].validate(async valid => {
        if (!valid) return this.loading = false;

        try {
          await this.modal.api.set(this.data);

          this.$message.success('提交成功');
          this.$refs['form'].resetFields();
          this.loading = false;
          this.show = false;
          this.$emit('ok');
        } catch(err) {
          this.loading = false;
          this.$message.error(err.message);
        }
      });
    },
    
    /*********************************************************
     * @description 上传组件回调 删除/上传成功
     *********************************************************/
    // 删除/上传成功
    onSuccess(key, res) {
      //- console.log('删除/上传成功', key, res)
      this.$set(this.data, key, res);
      //- this.form.setFieldsValue({ [key]: res });
    },
  },
  watch: {
    // 当 data 发生改变时，为表单设置值
    //- "data": {
    //-   handler() {
    //-     if (this.data.parent === '') this.data.parent = undefined;
    //-     //- if (this.data.gallery) this.data.gallery = JSON.stringify(this.data.gallery);
    //-     console.log(pick(this.data, this.fields))
    //-     this.form.setFieldsValue(pick(this.data, this.fields));
    //-   },
    //-   immediate: false,
    //- },
  },
  created() {
    // 防止表单未注册
    //- this.fields.map(v => this.form.getFieldDecorator(v));
  }
}
</script>

<style lang="less" scoped>
.ant-form-vertical {
  .ant-form-item {
    padding-bottom: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>