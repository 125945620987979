<template lang="pug">
  a-modal(
    :title='title'
    :width='width'
    :visible="show"
    :footer="footer"
    :confirmLoading="loading"
    :afterClose="afterClose"
    @ok="submit"
    @cancel="cancel")

    //- vc-waterfall.m-b-25(:data="table")
    vc-table.m-b-25(
      ref="table"
      :data="table")
</template>

<script>
import columns from '@/config/column.config'

import vcTable from '@/components/vcTable'
import vcWaterfall from '@/components/vcWaterfall'

export default {
  name: 'vcAttachment',
  components: {
    'vc-table': vcTable,
    'vc-waterfall': vcWaterfall
  },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      show: false,
      title: '媒体库',
      width: 720,
      footer: undefined,

      options: {},

      // 查询条件参数
      queryParam: {
        // type: 'a'
      },

      /*********************************************************
      * @description vc-table 表格对象
      *********************************************************/
      table: {
        loading: false,
        size: 'default',
        columns: columns.attachment_modal(this),
        source: [],

        api: parameter => {
          return this.$http.get('attachment', {
            params: Object.assign(parameter, this.queryParam)
          })
        },
        
        // 分页器
        pagination: {
          total: 0,
          current: 1,
          pageSize: 25,
          pageSizeOptions: ['25', '45', '65'],
        },
      },
    }
  },
  methods: {
    //- 打开
    open(options) {
      this.options = options;
      this.show = true;
      // this._getParent();
    },

    //- 关闭
    cancel() {
      this.loading = false;
      this.show = false;
      this.$emit('cancel');
    },

    //- 关闭后
    afterClose() {
      this.data = {};
      // this.form.resetFields();
    },

    //- 提交
    submit() {
      this.loading = false;
      this.show = false;

      const items = this.$refs.table.getData().filter(item => this.$refs.table.table_selected_row_keys.includes(item.id));
      this.$emit('ok', items, this.options);
    },
  }
}
</script>

<style lang="less" scoped>
</style>