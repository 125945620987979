<template lang="pug">
  .vc-waterfall
    //- pre {{ data }}
    
    //- 头部表格控件
    .table-nav

      //- 头部批量操作控件
      .do-action
        a-select.left(
          allow-clear
          v-model="action"
          :placeholder="action_placeholder"
          :disabled="!actions.length"
          style="width: 120px")
          a-select-option(:value="item.value" v-for="(item, index) in actions" :key="index") {{ item.label }}
        a-button.m-l-5(
          type="primary"
          :disabled="!action || !table_selected_row_keys.length"
          @click="actionClick") {{ action_button }}
        
      //- 分页
      a-pagination.right(
        v-model="pagination.current"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :show-less-items="pagination.showLessItems"
        :show-size-changer="pagination.showSizeChanger"
        :page-size-options="pagination.pageSizeOptions"
        :show-total="pagination.showTotal"
        @change="pageChange"
        @showSizeChange="pageSizeChange")
        

    a-card(hoverable style="width: 300px; float: left; margin: 0 15px 20px 0" v-for="(item, index) in data.source" :key="index")
      img(slot="cover" alt="example" :src="item.guid")
      template.ant-card-actions(slot="actions")
        a-icon(key="setting" type="setting")
        a-icon(key="edit" type="edit")
        a-icon(key="ellipsis" type="ellipsis")
      a-card-meta(:title="item.name" description="This is the description")

    .clearfix

</template>

<script>
import _extends from 'babel-runtime/helpers/extends';

export default {
  name: 'vc-waterfall',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    actions() {
      return _extends([], this.data.actions);
    },
    pagination() {
      return _extends({
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: [ '10', '20', '30', '40', '50' ],
        showLessItems: true,
        showSizeChanger: true,
        hideOnSinglePage: false,
        showTotal: total => `总共${total}条数据`
      }, this.data.pagination);
    },
    loading() {
      return this.data.loading || false;
    },
    source() {
      return _extends([], this.data.source);
    },
  },
  data() {
    return {
      action: undefined,
      action_button: '执行',
      action_placeholder: '批量操作',
    }
  },
  methods: {
    /*********************************************************
     * @description 翻页
     *********************************************************/
    pageChange(current) {
      this.$emit('change', {
        pagination: { current },
        ...this.table_state
      });
    },

    /*********************************************************
     * @description 改变每页显示数量
     *********************************************************/
    pageSizeChange(current, pageSize) {
      this.$emit('change', {
        pagination: { current, pageSize },
        ...this.table_state
      });
    },

    /*********************************************************
     * @description 表格批量操作控件
     *********************************************************/
    actionClick() {
      if (!this.action || !this.table_selected_row_keys.length) return;
      this.$emit('change', {
        action: { do: this.action, data: this.table_selected_row_keys }
      });
    },
  },
  created(){
    //- const { size } = this.data;
    //- if (size) this.table_size = size;
  }
}
</script>

<style lang="less">
.vc-waterfall  {
  position: relative;

  .table-nav {
    margin: 15px 0;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .do-action {
      float: left;
    }
  }
}
</style>